<template src="./logout.html"></template>
<script>
export default {
  data() {
    return {};
  },
  mounted: function () {
    const vm = this;
    vm.init();
  },
  methods: {
    init() {
      const vm = this;
      vm.axios.get(`${vm.$store.state.filooServer}logout`).then(() => {
        const isProjectPlan = vm.$session.get("isProject") ? vm.$session.get("isProject") : false;
        vm.$session.set("planServices", null);        
        vm.$session.set("servicesRoutes",null);
        vm.$session.destroy();
        localStorage.removeItem("planAndAddOnsData");
        if (isProjectPlan) vm.$router.push("/login-horas");
        else vm.$router.push("/login");
      });
    },
  },
};
</script>
<style lang="scss" src="./logout.scss">
</style>
